
body{
    background-color: darkgrey;
}
.container {
    background-color: #EEEAB1;
}
header {
    //padding-bottom: 2em;
}
main{
    padding-top: 2em;
}
.sales{
    background-color: #CB984D;
    border-radius: 25px;
    padding: 1em;
    margin-bottom: 2em;
    margin-top: 1.5em;
    h2{
        font-size: medium;
    }
}
#menu{
    .nav-bar {
        height: auto;
        margin: 0;
        padding: 0;
        text-align: center 
    }
}

a {
    color: black;
}

.footer{
    padding: 1em;
    background-color: #CE5236;
    .img-icon{
        width: 2em;
        padding-bottom: 1em;
    }

}